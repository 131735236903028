//关于掌阅 导航
const aboutnav = [{
  name: "发展历程",
  id: "develop",
  component: 'Develop',
}, {
  name: "企业文化",
  id: "culture",
  component: 'Culture',
}, {
  name: "奖项荣誉",
  id: "honor",
  component: 'Honor',
}]



//发展历程

const developList = [
  {
    year: '2025',
    list: [
      {
        date: '1月',
        info: ['公司旗下商标“掌阅”成功入选首批《北京重点商标保护名录》','发布年度好书榜单——掌阅“风帆好书榜——2024年度总编推荐”']
      }
    ]
  },
  {
    year: '2024',
    list: [
      {
        date: '12月',
        info: ['公司最新人工智能应用功能“掌阅AI辅助阅读”通过登记正式上线', '获DataEye短剧热力榜“2024年度最具影响力平台”', '荣获“2024年度中国版权影响力企业”奖项']
      },
      {
        date: '9月',
        info: ['在成立16周年庆典之际，掌阅科技推出全新升级的掌阅APP，实现纯净阅读、会员权益、多端同步、AI辅助阅读、听书畅读、阅读时长记录等重要更新', '荣列2023年度北京民营企业“文化产业百强”“中小百强”榜单，掌阅科技子公司北京得间科技有限公司荣列2023年度北京民营企业“文化产业百强”榜单']
      },
      {
        date: '8月',
        info: ['掌阅精选举办第四届“掌阅精选企业阅读节”，联合十余家优质出版方特别邀请10位大咖视频荐书，发起“书香赋能 阅见未来”好书共读活动']
      },
      {
        date: '1月',
        info: ['发布年度好书榜单——掌阅“风帆好书榜——2023年度总编推荐”', '国内阅读行业首款对话式AI应用“阅爱聊”通过生成式人工智能服务备案']
      }
    ]
  },
  {
    year: '2023',
    list: [
      {
        date: '9月',
        info: ['荣列2022年度北京民营企业“文化产业百强”榜单', '掌阅精选举办第三届“掌阅精选企业阅读节”，发布《2023年掌阅精选金融业数字阅读报告》']
      },
      {
        date: '7月',
        info: '“阅爱聊”入选北京市通用人工智能大模型行业应用典型场景案例，掌阅科技入选北京市通用人工智能产业创新伙伴计划第二批成员名单和北京市通用人工智能大模型应用场景需求榜单'
      },
      {
        date: '6月',
        info: ['宣布与国际出版集团企鹅兰登达成英文原版书电子书版权合作', '发布国内阅读行业首款对话式AI应用“阅爱聊”，开启封闭内测']
      },
      {
        date: '3月',
        info: '掌阅精选参与制定的《企业读书会创建运营管理规范》团体标准（标准编号：T/CECIA 08-2023）正式发布，并于4月23日正式实施'
      }
    ]
  },
  {
    year: '2022',
    list: [
      {
        date: '12月',
        info: '发布年度好书榜单——掌阅“风帆好书榜——2022年度总编推荐”'
      },
      {
        date: '9月',
        info: ['掌阅iReader数字阅读平台入选2022年度“数字出版优质平台遴选计划”', '入选“2022年度国家知识产权示范企业”', '掌阅精选举办第二届“掌阅精选企业阅读节”并发布全新升级的掌阅精选企业会员机构阅读方案']
      },
      {
        date: '3月',
        info: '掌阅精选宣布加入CARSI联盟，为高校用户使用掌阅精选图书资源提供更加便捷高效的方式'
      },
      {
        date: '1月',
        info: ['入选北京市2022年第一批“专精特新”中小企业', '发布《2021年度掌阅数字阅读报告》']
      }
    ]
  },
  {
    year: '2021',
    list: [
      {
        date: '12月',
        info: ['发布年度好书榜单——掌阅“风帆好书榜——2021年度总编推荐”', '举办掌阅2021年度电子书阅读盛典，发布了2021年度最具人气好书TOP10、最具影响力出版品牌、最具潜力出版品牌三大榜单']
      },
      {
        date: '11月',
        info: '掌阅科技宣布启用全新品牌标识。以书为媒，携手用户更好地探索、发现、认知世界和自我'
      }, {
        date: '10月',
        info: '召开“阅见极致”iReader 新品发布会，发布iReader Smart X2超级智能本、iReader Light2 Pro智能阅读本，并推出了创新自研的REINK智能排序引擎和一键换机两款新功能'
      }, {
        date: '9月',
        info: ['彩色墨水屏电子书阅读器iReader C6 Pro上市', '入选第十三届“全国文化企业30强”提名', '公司成立13周年升级发布文化3.0，以“自驱敢为、客观坦诚、简单高效、追求极致”的“掌阅范”为一致的行为倡导，打造持续奋斗、持续创业的组织。']
      },
      {
        date: '8月',
        info: '入选2021-2022年度“国家文化出口重点企业”'
      },
      {
        date: '7月',
        info: '荣膺第五届“中国出版政府奖”先进出版单位'
      },
      {
        date: '6月',
        info: ['荣获第二十二届“中国专利优秀奖”', '发布全新形象“小阅”及其周边']
      },
      {
        date: '5月',
        info: '召开“重塑想象”iReader 新品发布会，发布iReader Light2智能阅读本、iReader Smart Xs智能阅读本、 iReader Smart Xs Pro超级智能本及携带橡皮擦的第三代X-Pen电磁笔四款新品'
      },
      {
        date: '2月',
        info: '受人民出版社委托，开发并维护“中国共产党思想理论资源数据库‘悦读器’”'
      }
    ]
  }, {
    year: '2020',
    list: [{
      date: '11月',
      info: '入选第十二届“全国文化企业30强”提名'
    },
      {
        date: '10月',
        info: ['旗舰级超级智能本iReader Smart2上市', '同时荣列2020年度北京民营企业“文化产业百强”、“科技创新百强”、“企业社会责任百强”三大榜单']
      },
      {
        date: '5月',
        info: '荣获“国家知识产权优势企业”'
      },
      {
        date: '3月',
        info: '首款彩色墨水屏电子书阅读器iReader C6 上市'
      }
    ]
  }, {
    year: '2019',
    list: [{
      date: '11月',
      info: '旗舰级智能手写阅读本iReader SmartX上市'
    },
      {
        date: '9月',
        info: '获批成为“国家文化和科技融合示范基地”'
      },
      {
        date: '8月',
        info: ['掌阅作书匠“助残扶贫·居家就业”公益项目正式启动', '成为“学习强国”学习平台重要数字内容资源合作伙伴']
      },
      {
        date: '5月',
        info: '入选第十一届“全国文化企业30强”提名'
      },
      {
        date: '4月',
        info: '首款听读一体电子书阅读器iReader A6上市'
      },
      {
        date: '1月',
        info: '掌阅精选正式成立'
      }
    ]
  }, {
    year: '2018',
    list: [{
      date: '9月',
      info: '手写智能本电子书阅读器iReader Smart上市'
    },
      {
        date: '8月',
        info: '聚焦青少年课外阅读产品“掌阅课外书”正式上线'
      },
      {
        date: '5月',
        info: '入选第十届“全国文化企业30强”提名'
      },
      {
        date: '4月',
        info: ['“全民阅读 文化筑梦”公益项目正式启动', 'iReader 电子书阅读器iReader T6上市']
      }
    ]
  }, {
    year: '2017',
    list: [{
      date: '11月',
      info: 'iReader 电子书阅读器iReader Ocean上市'
    },
      {
        date: '9月',
        info: '掌阅科技在上海证券交易所主板挂牌上市'
      },
      {
        date: '6月',
        info: 'iReader电子书阅读器iReader Light青春版上市'
      }
    ]
  }, {
    year: '2016',
    list: [{
      date: '4月',
      info: '掌阅“作书匠”平台正式成立'
    }]
  }, {
    year: '2015',
    list: [{
      date: '10月',
      info: '掌阅国际版本正式上线'
    },
      {
        date: '8月',
        info: '第一代iReader电子书阅读器上市'
      },
      {
        date: '4月',
        info: '掌阅文学正式成立'
      }
    ]
  }, {
    year: '2014',
    list: [{
      date: '5月',
      info: '掌阅公版项目正式上线'
    },
      {
        date: '1月',
        info: '精品书项目正式上线'
      }
    ]
  }, {
    year: '2013',
    list: [{
      date: '3月',
      info: '业内率先实现了3D仿真翻页、护眼模式等技术创新'
    }]
  }, {
    year: '2011',
    list: [{
      date: '1月',
      info: '掌阅APP正式上线'
    }]
  }, {
    year: '2008',
    list: [{
      date: '9月',
      info: '掌阅科技股份有限公司正式成立'
    }]
  }]
//企业文化
const cultureList = [{
  title: "使命",
  info: '让阅读价值无处不在',
}, {
  title: "愿景",
  info: '做全球最专业的阅读平台',
}, {
  title: "掌阅范",
  info: '自驱敢为 客观坦诚 简单高效 追求极致',
}]
//奖项荣誉
const honorList = [
  {
    'src': 'https://static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor30.jpeg',
    honorName: '中国专利保护协会理事单位',
    honorInfo: ''
  },
  {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor29.png',
    honorName: '中国版权协会副理事长单位',
    honorInfo: ''
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor28.png',
    honorName: '中国出版协会常务理事单位',
    honorInfo: ''
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor27.png',
    honorName: '中国音像与数字出版协会副理事长单位',
    honorInfo: ''
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor26.png',
    honorName: '2021年，“第五届中国出版政府奖”先进出版单位',
    honorInfo: '颁发单位：国家新闻出版署'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor25.png',
    honorName: '2021年，“全国文化企业30强”提名',
    honorInfo: '颁发单位：光明日报社和经济日报社'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor24.png',
    honorName: '2021至2022年度，“国家文化出口重点企业”',
    honorInfo: '颁发单位：商务部、中宣部、财政部、文化和旅游部、广电总局'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor23.png',
    honorName: '2021年“北京市知识产权示范单位”',
    honorInfo: '颁发单位：北京市知识产权局'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor22.png',
    honorName: '“国家文化和科技融合示范基地”',
    honorInfo: '颁发单位：科技部、中宣部、网信办、文旅部、广电总局'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor21.png',
    honorName: '2019年，“2019-2022年度国家知识产权优势企业”',
    honorInfo: '颁发单位：国家知识产权局'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor20.png',
    honorName: '2019年，“CPCC十大中国著作权人”',
    honorInfo: '颁发单位：中国版权保护中心'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor19.png',
    honorName: '2019年度，北京民营企业文化产业百强',
    honorInfo: '颁发单位：北京市工商联'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor18.png',
    honorName: '2019年度，北京民营企业科技创新百强',
    honorInfo: '颁发单位：北京市工商联'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor17.png',
    honorName: '2019年度，北京民营企业社会责任百强',
    honorInfo: '颁发单位：北京市工商联'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor16.png',
    honorName: '2019至2020年度,“国家文化出口重点企业”',
    honorInfo: '颁发单位：商务部、中宣部、财政部、文化和旅游部、广电总局'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor15.png',
    honorName: '2019年,“北京市知识产权示范单位”',
    honorInfo: '颁发单位：北京市知识产权局'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor14.png',
    honorName: '2018年,“全国文化企业30强”提名',
    honorInfo: '颁发单位：光明日报社和经济日报社'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor13.png',
    honorName: '2018年度，北京民营企业文化产业百强',
    honorInfo: '颁发单位：北京市工商联'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor12.png',
    honorName: '2018年，“首都新闻出版广电‘走出去’示范企业”',
    honorInfo: '颁发单位：北京市新闻出版广电局'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor11.png',
    honorName: '2018年，“首都文明单位”',
    honorInfo: '颁发单位：首都精神文明建设委员会'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor10.png',
    honorName: '2018年，“第四届中国出版政府奖”先进出版单位',
    honorInfo: '颁发单位：国家新闻出版署'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor9.png',
    honorName: '2017至2018年度,“国家文化出口重点企业”',
    honorInfo: '颁发单位：商务部、中宣部、财政部、文化和旅游部、广电总局'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor8.png',
    honorName: '2017年度,北京民营企业文化产业百强',
    honorInfo: '颁发单位：北京市工商联'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor7.png',
    honorName: '2017年度,北京民营企业科技创新百强',
    honorInfo: '颁发单位：北京市工商联'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor6.png',
    honorName: '2017年度,北京民营企业社会责任百强',
    honorInfo: '颁发单位：北京市工商联'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor5.png',
    honorName: '2016年，“出版融合发展重点实验室”',
    honorInfo: '颁发单位：国家新闻出版署'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor4.png',
    honorName: '2016年，“新闻出版业科技与标准重点实验室”',
    honorInfo: '颁发单位：国家新闻出版署'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor3.png',
    honorName: '2015年，“第四届世界知识产权组织版权金奖”',
    honorInfo: '颁发单位：世界知识产权组织'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor2.png',
    honorName: '2014年，“全国版权示范单位”',
    honorInfo: '颁发单位：国家版权局'
  }, {
    'src': '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor1.png',
    honorName: '2013年，“中国版权最具影响力企业”',
    honorInfo: '颁发单位：中国版权协会'
  }
]
//掌阅公益
// const zyreps = [{
//     title: '全民阅读 文化筑梦',
//     from: '来源：掌阅科技',
//     list: [{
//         content: '为了更好地推广并号召全社会关注"全民阅读"，同时按照国家扶贫攻坚计划中通过文化教育扶贫一批的指示，助力实现中华民族伟大复兴的"中国梦"，掌阅科技于2018年在公司成立十周年之际，策划启动了"全民阅读∙文化筑梦"公益项目，为贫困地区、少数民族聚居区、革命老区等地区捐赠爱心阅读室，培养民众特别是青少年养成良好的阅读习惯，普及优秀的传统文化和高品质内容，扩展知识面，丰富精神生活，通过推广全民阅读，让更广泛地区、更多人群享受到阅读的乐趣，甚至摆脱贫困。',
//         url: '',
//         content2: '该项目由书香中国·北京阅读季领导小组指导，掌阅联合新华社—中国经济信息社有限公司北京分公司共同主办，由权威出版机构、中央媒体等单位及掌阅文学作者共同参与。截至2021年5月，项目已先后在河北省康保县、河北省滦平县、内蒙古卓资县，内蒙古呼和浩特市辖内各旗县，四川省甘孜藏族自治州，以及红色革命圣地江西井冈山等地进行了捐赠，累计送出电子书阅读器350余台、电子书数十万余册、纸质书一万余册，书架文具若干，建成乡村爱心阅览室近90间。',
//         content3: '在2020年中国数字阅读云上大会上，掌阅“全民阅读 文化筑梦”公益项目荣获“2019年度十佳阅读扶贫项目”。同时荣获第十届中国公益节“2020年度公益创新奖”。',
//     }, {
//         subtitl: '河北省承德市滦平县',
//         content: '素有"普通话之乡"之称的滦平县此前是国家级贫困县，也是人民日报海外版的定点扶贫县，学生学习条件艰苦，特别是缺少课外读物，阅读环境亟待改善。2018年世界读书日前夕，"全民阅读∙文化筑梦"公益项目在河北省承德市滦平县巴克什营镇古城川小学正式启动，将滦平县11所条件较差的小学校和教学点作为捐赠地，联合上海译文出版社、花城出版社、天地出版社、以及北京日知图书有限公司，以及掌阅文学爱心作者共同捐赠了电子书阅读器、电子书、纸质书、笔记本电脑、打印机、书架、文具等大量物资。',
//         url: ''
//     }, {
//         subtitl: '内蒙古自治区呼和浩特市',
//         content: '内蒙古自治区呼和浩特市和林格尔县及周边地区教育资源匮乏，公共教育资源分配不均衡，以和林格尔县董家营小学为例，全校6个年级90名学生人均课外书不足5本，且内容老旧。2018年10月，项目团队与掌阅联合创始人王良先生、掌阅文学总编及爱心作者一起，为内蒙古呼和浩特市下辖的清水河县、和林格尔县、土默特左旗、托克托县、五川县5个旗县和赛罕区的10所小学进行了捐赠，包括电子书阅读器、纸质图书、书架及练习本等文具，共覆盖3027名蒙古族和汉族学生。',
//         url: ''
//     }, {
//         subtitl: '四川省甘孜藏族自治州',
//         content: '四川省甘孜藏族自治州是国家深度贫困地区的“三区”之一。 “三区三州"深度贫困区80%以上区域位于青藏高原区，自然条件差、经济基础弱、贫困程度深，是我国脱贫攻坚的难中之难、坚中之坚任务之一。为打赢"三区三州"控辍保学扶贫攻坚战，2019年4月，掌阅公益积极参与中国音像与数字出版协会发起的公益活动，向四川省甘孜藏族自治州中小学捐赠了100台电子书阅读器，帮助当地建成电子阅览室30余间，助力文化教育扶贫，携手共筑精神文化家园。',
//         url: ''
//     }, {
//         subtitl: '内蒙古自治区乌兰察布市卓资县',
//         content: '2018年11月，在朝阳区工商联举办的“万企帮万村”精准扶贫洽商会上，掌阅科技与内蒙古卓资县签订了意向帮扶协议，拟为当地10所贫困学校建立电子阅览室。2019年5月，掌阅党支部与掌阅公益团队先后两次赶赴内蒙古卓资县，履行职责，捐赠了10间爱心阅览室，包括40余台iReader电子书阅读器、数十万册电子书、2000余册纸质书、文具等物资。 “三区三州"深度贫困区80%以上区域位于青藏高原区，自然条件差、经济基础弱、贫困程度深，是我国脱贫攻坚的难中之难、坚中之坚任务之一。为打赢"三区三州"控辍保学扶贫攻坚战，2019年4月，掌阅公益积极参与中国音像与数字出版协会发起的公益活动，向四川省甘孜藏族自治州中小学捐赠了100台电子书阅读器，帮助当地建成电子阅览室30余间，助力文化教育扶贫，携手共筑精神文化家园。',
//         url: ''
//     }, {
//         subtitl: '内蒙古自治区',
//         content: '2019年7月，公益项目携手人气演员王晓晨、知名作家果味喵、知名作家歌怨为内蒙自治区联合捐赠10间爱心阅读室。此次捐赠的物品包括40台iReader电子阅读器、5000余册纸质书、20000余本作业本以及若干文具。 “三区三州"深度贫困区80%以上区域位于青藏高原区，自然条件差、经济基础弱、贫困程度深，是我国脱贫攻坚的难中之难、坚中之坚任务之一。为打赢"三区三州"控辍保学扶贫攻坚战，2019年4月，掌阅公益积极参与中国音像与数字出版协会发起的公益活动，向四川省甘孜藏族自治州中小学捐赠了100台电子书阅读器，帮助当地建成电子阅览室30余间，助力文化教育扶贫，携手共筑精神文化家园。',
//         url: ''
//     }, {
//         subtitl: '河北康保县和内蒙古卓资县',
//         content: '2021年初，为满足青少年学习热忱,营造先进学习环境,提供科技学习工具,掌阅公益行再次走进内蒙古自治区卓资县、河北省康保县,为两地两所学校60位贫困户学生捐赠了60台笔记本电脑,圆了孩子们一个电脑梦。 “三区三州"深度贫困区80%以上区域位于青藏高原区，自然条件差、经济基础弱、贫困程度深，是我国脱贫攻坚的难中之难、坚中之坚任务之一。为打赢"三区三州"控辍保学扶贫攻坚战，2019年4月，掌阅公益积极参与中国音像与数字出版协会发起的公益活动，向四川省甘孜藏族自治州中小学捐赠了100台电子书阅读器，帮助当地建成电子阅览室30余间，助力文化教育扶贫，携手共筑精神文化家园。',
//         url: ''
//     }, {
//         subtitl: '革命圣地江西井冈山',
//         content: '2021年5月，在中国音像与数字出版协会指导下，掌阅联合江西人民出版社共同承办"2021全民阅读走进井冈山活动"，并进行了捐赠。其中，掌阅“全民阅读 文化筑梦”公益项目向井冈山小学、碧溪中心小学、碧溪三峰小学、拿山小学、井冈山实验学校捐赠了50台iReader电子书阅读器以及掌阅年度VIP畅读卡50张，每张含电子书20万册，免费畅读1年，以及200余册优质纸质图书。,营造先进学习环境,提供科技学习工具,掌阅公益行再次走进内蒙古自治区卓资县、河北省康保县,为两地两所学校60位贫困户学生捐赠了60台笔记本电脑,圆了孩子们一个电脑梦。 “三区三州"深度贫困区80%以上区域位于青藏高原区，自然条件差、经济基础弱、贫困程度深，是我国脱贫攻坚的难中之难、坚中之坚任务之一。为打赢"三区三州"2021年5月，在中国音像与数字出版协会指导下，掌阅联合江西人民出版社共同承办"2021全民阅读走进井冈山活动"，并进行了捐赠。其中，掌阅“全民阅读 文化筑梦”公益项目向井冈山小学、碧溪中心小学、碧溪三峰小学、拿山小学、井冈山实验学校捐赠了50台iReader电子书阅读器以及掌阅年度VIP畅读卡50张，每张含电子书20万册，免费畅读1年，以及200余册优质纸质图书。',
//         url: ''
//     }]
// }]

//掌阅产品
const downList = [{
  name: 'Android版下载',
  href: 'https://ah2.zhangyue.com/zybook3/app/app.php?ca=Down.Index&p2=108044%27',
  href_app: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.zhangyue.read.edu#',
  icon: 'android',
}, {
  name: 'App Store下载',
  href: 'https://itunes.apple.com/cn/app/ireader-du-shu/id463150061?mt=8',
  icon: 'ios',
}]


export {aboutnav, developList, cultureList, honorList, downList}
