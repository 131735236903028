<template>
  <div id="brand" class="zy_brand">
    <div id="zy_brand_app" class="zy_brand_app">
      <div class="pc_wraper">
        <div class="zy_brand_info">
          <span class="logo" />
          <p class="zy_p_small" v-html="brand.desc" />
          <div class="zy_brand_down">
            <a
              class="zy_brand_btn"
              v-for="item in downList"
              :class="item.icon"
              :key="item.name"
              :href="item.href"
            >{{item.name}}</a>
          </div>
          <span class="zy_brand_qr" />
        </div>
        <div class="zy_brand_icon" />
      </div>
    </div>
    <div class="zy_brand_app">
      <span class="logo" />
      <div class="zy_brand_icon" />
      <p>引领品质阅读</p>
      <div class="zy_brand_down">
        <a class="" :href="!isIos?downList[0].href:downList[1].href">立即下载</a>
      </div>
    </div>
    <div class="zy_brand_product" :class="flag?'up_h':''">
      <div class="zy_brand_list" v-for="item in brandList" :key="item.name">
        <a class="zy_brand_img" :class="item.icon" :href="item.link" />
        <a class="zy_brand_name" :href="item.link">{{item.name}}</a>
      </div>
      <div class="deni">
        <div class="content"></div>
      </div>
    </div>
    <div v-if="flag" class="more_down">
      <a class="text-blue" @click="flag = !flag">{{flag?'查看更多':''}}</a>
    </div>
  </div>
</template>

<script>
import { setting } from "@/config";
export default {
  name: "Brand",
  data: function() {
    const { brand } = setting;
    const { brandList, downList } = brand;
    return {
      brand,
      brandList,
      downList,
      isIos: false,
      flag: false,
    };
  },
  created() {
      this.appSource();
  },
  methods:{
      appSource() {
          const u = navigator.userAgent;
          const isiOS = /(iPhone|iPad|iPod|iOS)/i.test(u);
          
          if (isiOS) {
              // return "ios";
              this.isIos = true
          } else {
              this.isIos = false
          }
      }
  },
  mounted() {
    this.flag = true
  }
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.zy_brand {
  height: 890px;
  .more_down{
    display: none;
  }
}
.pc_wraper{
  width: 1038px;
  margin:0 auto;
}

.S .zy_brand {
  height: unset;
}

.S .zy_brand_app,
.zy_brand_app[id] {
  display: block;
}

.S .zy_brand_app[id],
.zy_brand_app {
  display: none;
}

.zy_brand_app {
  width: 100%;
  height: 613px;
  background-image: linear-gradient(to bottom, #f3f3f3, #e6e6e6);
  font-size: 0px;
  padding: 0px 224px;
  white-space: nowrap;
  box-sizing: border-box;

  .zy_brand_info {
    padding: 110px 0px 109px;
    width: 333px;
    display: inline-block;
    vertical-align: top;
    span {
      display: inline-block;
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .logo {
      width: 320px;
      height: 50px;
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/brand_logo.cd0df787.png");
    }
    p {
      margin: 24px 0px 40px;
      white-space: normal;
    }
    .zy_brand_qr {
      width: 110px;
      height: 110px;
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/brand_down.67c3ed5a.png");
    }
    .zy_brand_down {
      font-size: 14px;
      display: inline-block;
      vertical-align: top;
      margin-right: 16px;
      .zy_brand_btn {
        display: block;
        width: 194px;
        height: 48px;
        box-sizing: border-box;
        border-radius: 2px;
        border: 1px solid #666;
        margin-bottom: 14px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0px;
        }
        &:before {
          content: "";
          width: 18px;
          height: 22px;
          display: inline-block;
          margin: 11px 13px 12px 42px;
          vertical-align: middle;
          background-size: 100%;
          background-repeat: no-repeat;
        }

        &.android {
          &:before {
            margin: 12px 13px 14px 42px;
            background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/android.png");
          }
          span {
            vertical-align: text-bottom;
          }
        }

        &.ios {
          &:before {
            margin: 12px 13px 14px 42px;
            background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/ios.png");
          }

          span {
            vertical-align: sub;
          }
        }

        &.web {
          &:before {
            margin: 12px 13px 14px 42px;
            background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/0e7f32f0-c279-4968-b5d6-6742172b532a.png");
          }

          span {
            vertical-align: text-bottom;
          }
        }

        &:hover {
          color: #fff;
          background-image: linear-gradient(to bottom right, #ff7252, #ff4d40);
          border: none;

          &.ios {
            &:before {
              background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/ios_active.png");
            }
          }
          &.android {
            &:before {
              background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/android_active.png");
            }
          }
          &.web {
            &:before {
              background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/d29a8507-7ab2-43e9-ac66-da9e0bfb9716.png");
            }
          }
        }
      }
    }
  }

  .zy_brand_icon {
    display: inline-block;
    width: 618px;
    height: 455px;
    background-size: 100%;
    margin: 57px 0px 0px 87px;
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/a035286e-e0a7-4582-a650-df577d8b53bb.png");
  }
}

.S .zy_brand_app {
  padding: 0px;
  width: 100%;
  height: rem(1395);
  padding-top: rem(156);
  .logo {
    display: block;
    width: rem(211);
    height: rem(89);
    margin: 0 auto rem(9);
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/brand_logo_icon.ee4d76c0.png");
    background-size: 100%;
    background-repeat: no-repeat;
  }
  p {
    padding-left: rem(50);
    font-size: rem(48);
    color: $mainDarkText;
    letter-spacing: rem(48);
    text-align: center;
    line-height: rem(64);
  }
  .zy_brand_icon {
    width: 100%;
    height: rem(782);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 0 rem(56);
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/4ba7f757-f05f-4eef-bcb2-17c16bda2656.png");
  }
  .zy_brand_down {
    height: rem(139);
    margin: rem(70) rem(60) 0;
    border-radius: rem(6);
    font-size: rem(42);
    color: #fff;
    text-align: center;
    line-height: rem(139);
    font-weight: bold;
    background-image: linear-gradient(to bottom right, #ff7252, #ff4d40);
  }
}

.zy_brand_product {
  width: 830px;
  margin:0 auto;
  padding: 70px 0;
  .zy_brand_list {
    width: 166px;
    height: 122px;
    display: inline-block;
    text-align: center;
    cursor: pointer;

    .zy_brand_img {
      width: rem(150);
      height: rem(150);
      display: inline-block;
      margin: 20px 0px 12px;
      background-size: 100%;
      &.ireader {
        background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/ireader.7b45d104.png");
      }
      &.zy_jx {
        background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/zy_jx.583ee034.png");
      }
      &.zy_kw {
        background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/zy_kw.72754403.png");
      }
      &.cartoon {
        background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/cartoonout.688472dc.png");
      }
      &.school {
        background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/school.afc93017.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size:48px;
      }
      &.cartoonout {
        background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/cartoonout.688472dc.png");
      }
    }
    .zy_brand_name {
      display: block;
      font-size: 14px;
      color: #333;
      &:hover {
        color: $mainActive;
      }
    }
  }
}

.S .zy_brand_product {
  width: auto;
  padding: rem(164) rem(80) rem(124);
  position: relative;
  &.up_h{
    height: rem(705);
    overflow: hidden;
  }
  .zy_brand_list {
    width: 50%;
    height: rem(257);
    padding: rem(49) 0;
    box-sizing: border-box;
    .zy_brand_img {
      width: rem(99);
      height: rem(99);
      margin: 0;
      &.school{
        background-size: 100%;
      }
    }
    .zy_brand_name {
      font-size: rem(36);
    }
  }
  .deni {
    width: rem(460 * 2);
    height: rem(257 * 4);
    position: absolute;
    top: rem(164);
    left:50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    overflow: hidden;
    z-index: -1;
    .content {
      position: absolute;
      right: rem(3);
      top: rem(-3);
      margin-left: auto;
      margin-right: auto;
      width: rem(460 * 2);
      height: rem(257 * 4);
      border-width: rem(3);
      border-left-width: 0;
      border-top-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
      border-style: solid;
      border-color: #fafafa;
      background-image: linear-gradient(#fafafa 1px, transparent 0),
        linear-gradient(90deg, #fafafa 1px, transparent 0);
      background-size: rem(460) rem(257 + 3);
    }
  }
}
.S{
  .more_down{
    display: block;
    padding: rem(72) 0 rem(124);
    text-align: center;
    .text-blue{
      position: relative;
      display: inline-block;
      padding-right:rem(32);
      font-size:rem(36);
      color:#999;
      &:after{
        content:'';
        position: absolute;
        right:0;
        top:50%;
        width: rem(16);
        height: rem(16);
        margin-top:rem(-8);
        -webkit-transform: rotate(90deg);
        transform:  rotate(90deg);
        background:url('//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/more.png') no-repeat center;
        -webkit-background-size:rem(16);
        background-size:rem(16);
      }
    }
  }
}
</style>
